import { Component, OnInit, ViewChild, Input, Inject, PLATFORM_ID } from '@angular/core';

import { MapsAPILoader } from '@agm/core';

import { isPlatformBrowser } from '@angular/common';
import { ServiceService } from '../service.service';
import { interval } from 'rxjs';
import { MainService } from 'src/app/core/services/main.service';
import { formObject } from 'src/app/pages/helper/formObject';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-tracking-live',
  templateUrl: './tracking-live.component.html',
  styleUrls: ['./tracking-live.component.scss']
})

/**
 * Google map component - handling the google map with sidebar and content
 */
export class TrackingLiveComponent implements OnInit {

  tripsList = [];
  selectedAttributes = [];
  objectForm = new formObject;
  // bread crumb items
  breadCrumbItems: Array<{}>;
  iconUrl = "assets/maps/car-icon.png";
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private mapsAPILoader: MapsAPILoader,
    private service: ServiceService,
    private mainService: MainService,
    public formBuilder: FormBuilder,
  ) {
    this.mainService.changeBreadCrumbItems([
      { label: 'Report Trip Tracking Live', path: '', active: true },
    ]);
    this.mainService.setTitle("Report Trip Tracking Live");
    this.getIndexData(true);
    interval(10000).subscribe(x => {
      this.getIndexData();
    });
  }

  ngOnInit() {
    this.objectForm.formGroup = this.formBuilder.group({
      vehicles_id: [],
    });
  }

  getIndexData(first = false) {
    this.service.getAllTrackingLive().subscribe((data: any) => {
      this.tripsList = data.data;
      if (first) {
        // this.selectedAttributesDefault = this.tripsList;
        this.tripsList.forEach(element => {
          this.selectedAttributes.push(element.vehicle_id);
        });
        this.objectForm.controller.vehicles_id.setValue(this.selectedAttributes);
      }
      console.log(this.tripsList);
    });
  }

  onChange(vehicle_number) {

    this.selectedAttributes = [];
    vehicle_number.forEach(element => {
      this.selectedAttributes.push(element.vehicle_id);
    });

  }

}
