import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [

  { path: 'dashboards', loadChildren: () => import('./dashboards/dashboards.module').then(m => m.DashboardsModule) },
  { path: 'languages', loadChildren: () => import('./languages/languages.module').then(m => m.LanguagesModule) },
  { path: 'governments', loadChildren: () => import('./governments/governments.module').then(m => m.GovernmentsModule) },
  { path: 'my_account', loadChildren: () => import('./my-account/my-account.module').then(m => m.MyAccountModule) },
  { path: 'agencies', loadChildren: () => import('./agencies/agencies.module').then(m => m.AgenciesModule) },
  { path: 'driver_license_types', loadChildren: () => import('./driver-license-types/driver-license-types.module').then(m => m.DriverLicenseTypesModule) },
  { path: 'drivers', loadChildren: () => import('./drivers/drivers.module').then(m => m.DriversModule) },
  { path: 'vehicle_models', loadChildren: () => import('./vehicle-models/vehicle-models.module').then(m => m.VehicleModelsModule) },
  { path: 'vehicle_colors', loadChildren: () => import('./vehicle-colors/vehicle-colors.module').then(m => m.VehicleColorsModule) },
  { path: 'vehicle_types', loadChildren: () => import('./vehicle-types/vehicle-types.module').then(m => m.VehicleTypesModule) },
  { path: 'vehicle_capacities', loadChildren: () => import('./vehicle-capacities/vehicle-capacities.module').then(m => m.VehicleCapacitiesModule) },
  { path: 'vehicle_fuel_types', loadChildren: () => import('./vehicle-fuel-types/vehicle-fuel-types.module').then(m => m.VehicleFuelTypesModule) },
  { path: 'network_operators', loadChildren: () => import('./network-operators/network-operators.module').then(m => m.VehicleFuelTypesModule) },
  { path: 'tracking_devices', loadChildren: () => import('./tracking-devices/tracking-devices.module').then(m => m.TrackingDevicesModule) },
  { path: 'vehicles', loadChildren: () => import('./vehicles/vehicles.module').then(m => m.VehiclesModule) },
  { path: 'assistants', loadChildren: () => import('./assistants/assistants.module').then(m => m.AssistantsModule) },
  { path: 'supervisors', loadChildren: () => import('./supervisors/supervisors.module').then(m => m.SupervisorsModule) },
  { path: 'schools', loadChildren: () => import('./schools/schools.module').then(m => m.SchoolsModule) },
  { path: 'education_periods', loadChildren: () => import('./education-periods/education-periods.module').then(m => m.EducationPeriodsModule) },
  { path: 'questions', loadChildren: () => import('./questions/questions.module').then(m => m.QuestionsModule) },
  { path: 'contact_us_cause_of_problems', loadChildren: () => import('./contact-us-cause-of-problems/contact-us-cause-of-problems.module').then(m => m.ContactUsCauseOfProblemsModule) },
  { path: 'passengers', loadChildren: () => import('./passengers/passengers.module').then(m => m.PassengersModule) },
  { path: 'students', loadChildren: () => import('./students/students.module').then(m => m.StudentsModule) },
  { path: 'packages', loadChildren: () => import('./packages/packages.module').then(m => m.PackagesModule) },
  { path: 'trip_packages', loadChildren: () => import('./trip-packages/trip-packages.module').then(m => m.TripPackagesModule) },
  { path: 'schools_saas_packages', loadChildren: () => import('./schools-saas-packages/schools-saas-packages.module').then(m => m.SchoolsSaasPackagesModule) },
  { path: 'trips', loadChildren: () => import('./trips/trips.module').then(m => m.TripsModule) },
  { path: 'fixed_trips', loadChildren: () => import('./fixed-trips/fixed-trips.module').then(m => m.FixedTripsModule) },
  { path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule) },
  { path: 'maps', loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule) },
  { path: 'roles', loadChildren: () => import('./roles/roles.module').then(m => m.RolesModule) },
  { path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule) },
  { path: 'complaints', loadChildren: () => import('./complaints/complaints.module').then(m => m.ComplaintsModule) },
  { path: 'requests', loadChildren: () => import('./requests/requests.module').then(m => m.RequestsModule) },
  { path: 'trips_subs', loadChildren: () => import('./sub/sub.module').then(m => m.SubModule) },
  { path: 'request_status', loadChildren: () => import('./request-status/request-status.module').then(m => m.RequestStatusModule) },
  { path: 'trip_cancels', loadChildren: () => import('./trip-cancels/trip-cancels.module').then(m => m.TripCancelssModule) },
  { path: 'trip_cancels_pssengers', loadChildren: () => import('./trip-cancels-passenger/trip-cancels-passenger.module').then(m => m.TripCancelssPassengerModule) },
  { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) },
  { path: 'trip_package_car_types', loadChildren: () => import('./trip-package-car-types/trip-package-car-types.module').then(m => m.TripPackageCarTypesModule) },
  { path: 'trip_package_type_features', loadChildren: () => import('./trip-package-car-type-features/trip-package-car-type-features.module').then(m => m.TripPackageCarTypeFeaturesModule) },
  { path: 'trip_package_types', loadChildren: () => import('./trip-package-types/trip-package-types.module').then(m => m.TripPackageTypesModule) },
  { path: 'payment_methods', loadChildren: () => import('./payment-methods/payment-methods.module').then(m => m.PaymentMethodsModule) },
  { path: 'coupons', loadChildren: () => import('./coupons/coupons.module').then(m => m.CouponsModule) },
  { path: 'invoices', loadChildren: () => import('./invoices/invoices.module').then(m => m.InvoicesModule) },
  { path: 'notifications_custom', loadChildren: () => import('./notifications-custom/notifications-custom.module').then(m => m.NotificationsCustomModule) },
  { path: 'logs', loadChildren: () => import('./logs/logs.module').then(m => m.LogsModule) },
  { path: 'ticket_complaints', loadChildren: () => import('./ticket-complaints/ticket-complaints.module').then(m => m.TicketComplaintsModule) },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
