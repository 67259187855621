import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss']
})
export class PackagesComponent implements OnInit, AfterViewInit {


  packages_list = [];
  loginFormGroup: FormGroup; // horizontal form validation
  loginFormGroupSubmit = false;
  loginFormGroupSuccessMessage = "";
  loginFormGroupErroMessage = "";
  loginFormGroupErrosMessage = null;
  loginFormGroupLoader = false;

  returnUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,

  ) {
    this.getIndexData();
  }

  ngOnInit() {
    this.loginFormGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      password: ['', Validators.required],
    });

    // reset login status
    // this.authenticationService.logout();

    // get return url from route parameters or default to '/'
    // tslint:disable-next-line: no-string-literal
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  getIndexData() {
    this.authService.getAllSaasPackages().subscribe((data: any) => {
      this.packages_list = data;
      console.log(data);
    });
  }

  ngAfterViewInit() {
    document.body.classList.add('authentication-bg');
    document.body.classList.add('authentication-bg-pattern');
  }

  // convenience getter for easy access to form fields
  get loginFormGroupController() { return this.loginFormGroup.controls; }

  /**
  * Login Form  validation form submit
  */
  loginFormGroupSubmitFunction() {
    this.loginFormGroupErroMessage = "";
    this.loginFormGroupSuccessMessage = "";
    this.loginFormGroupErrosMessage = [];
    this.loginFormGroupSubmit = true;
    this.loginFormGroupLoader = true;
    if (!this.loginFormGroup.valid) {
      this.loginFormGroupLoader = false;
      return;
    }


    this.authService.postuserLogin(this.loginFormGroup.value).subscribe((data: any) => {
      this.loginFormGroupLoader = false;
      if (data.status == 1) {
        this.loginFormGroupErroMessage = "";
        this.loginFormGroupSuccessMessage = "Successfully Updated Profile";
        this.loginFormGroupSubmit = false;
        this.authService.setUser(data.object, "users");
        this.router.navigate([this.returnUrl])
      }
      else {
        this.loginFormGroupSuccessMessage = "";
        this.loginFormGroupErrosMessage = data.errors;
        console.log(this.loginFormGroupErrosMessage);
      }
    });
  }

}
