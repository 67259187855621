import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DriverLicenseTypesRoutingModule } from './driver-license-types-routing.module';
import { CreateUpdateComponent } from './create-update/create-update.component';
import { IndexComponent } from './index/index.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [IndexComponent, CreateUpdateComponent],
  imports: [
    CommonModule,
    DriverLicenseTypesRoutingModule,
    NgbPaginationModule,
    UiSwitchModule,
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class DriverLicenseTypesModule { }
