// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ws_url: "https://socketdev.schoolz.io",
  firebase: {
    apiKey: "AIzaSyCh_shN5qFgHiAKUgZYoDCKrUaBidy7a9Q",
    authDomain: "schoolz-281412.firebaseapp.com",
    databaseURL: "https://schoolz-281412.firebaseio.com",
    projectId: "schoolz-281412",
    storageBucket: "schoolz-281412.appspot.com",
    messagingSenderId: "429366491982",
    appId: "1:429366491982:web:44fcbe6770b42f0d42fc1f",
    measurementId: "G-V8153MKHS3"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
