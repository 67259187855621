import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/auth.service';
import { MainService } from '../services/main.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private mainService: MainService,

    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUser();
        if (currentUser) {
            // logged in so return true
            // if (this.authenticationService.quard == "user") {
            this.mainService.getLanguages();
            // }


            return true;
        }

        //
        let redirect_guard = 'school';
        var host = window.location.host.split('.');
        if (host.length == 2) {
            redirect_guard = 'users';
        }
        //

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/account/' + redirect_guard + '/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}