import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './layouts/layout.component';
import { AuthGuardDomain } from './core/guards/auth.guardDomain';
import { PackagesComponent } from './saas/packages/packages.component';
import { AuthGuardDomainMain } from './core/guards/auth.guardDomainMain';


const routes: Routes = [


  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule), canActivate: [AuthGuardDomain] },
  { path: 'error', loadChildren: () => import('./error/error.module').then(m => m.ErrorModule) },
  { path: 'saas', loadChildren: () => import('./saas/account.module').then(m => m.AccountModule), },
  {
    path: '',
    component: PackagesComponent,
    canActivate: [AuthGuardDomainMain]
  },
  // { path: 'account/users', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  // { path: 'account/schoolz', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  { path: 'dashboard', component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard, AuthGuardDomain] },
  // { path: '', component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard,AuthGuardDomain] },
  // { path: '**', redirectTo: "user/dashboards" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
