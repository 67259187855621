import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    expxt = [
        "https://maps.googleapis.com/maps/api/geocode/json"]
    ;
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const currentUser = this.authenticationService.currentUser();
        const lang = this.authenticationService.lang;
        const page_count = this.authenticationService.mainService.page_count;
        // console.log(page_count);
        var found = false;
        this.expxt.forEach(element => {
            if(request.url.includes(element)){
                found =true;
                return next.handle(request);
            }
        });
        if (found==false && currentUser && currentUser.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `${currentUser.token}`,
                    lang: `${lang}`,
                    page_count :`${page_count}`,
                    // 'Content-Type': 'multipart/form-data',
                }
            });
        }

        return next.handle(request);
    }
}
