import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/core/services/main.service';
import { ServiceService } from '../service.service';
import { AuthenticationService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  object = null;
  filter = { page: 1 };
  page = 1;
  pageSize = 0;
  total = 0;
  constructor(
    private mainService: MainService,
    private service: ServiceService,
    private authService: AuthenticationService,
  ) {
    this.mainService.changeBreadCrumbItems([
      { label: 'Driver License Types', path: '', active: true },
    ]);
    this.mainService.setTitle("Driver License Types List");
    this.getIndexData();
  }

  ngOnInit() {

  }

  getIndexData() {
    this.service.getAll(this.filter).subscribe((data: any) => {
      this.object = data.data;
      this.total = data.data.total;
      this.pageSize = data.data.per_page;
      console.log(this.object);
    });
  }

  pageChange(page: number) {
    this.filter.page = page;
    this.getIndexData();
  }

  deleteObject(id) {
    this.service.deleteObject(id).subscribe((data: any) => {
      if (data.status == 1) {
        this.mainService.sweetAlertMessage("Deleted!", 'Your file has been deleted.');
        this.mainService.show_notifications("Successfully Deleted");
      }
      if (data.status == 0) {
        this.mainService.sweetAlertMessage("Deleted!", "Your file has't been deleted.", 'error');
        this.mainService.showFormErrors(data.errors);
      }
      this.getIndexData();
    });
  }

}
