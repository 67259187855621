import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VehicleModelsRoutingModule } from './vehicle-models-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { IndexComponent } from './index/index.component';
import { CreateUpdateComponent } from './create-update/create-update.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [IndexComponent, CreateUpdateComponent],
  imports: [
    CommonModule,
    VehicleModelsRoutingModule,
    NgbPaginationModule,
    UiSwitchModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule
  ]
})
export class VehicleModelsModule { }
