import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { ErrorInterceptor } from './core/helpers/error.interceptor';
import { JwtInterceptor } from './core/helpers/jwt.interceptor';
import { FakeBackendProvider } from './core/helpers/fake-backend';

import { LayoutsModule } from './layouts/layouts.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SimpleNotificationsModule } from 'angular2-notifications';

import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  PB_DIRECTION,
  NgxUiLoaderRouterModule,
  NgxUiLoaderHttpModule
} from 'ngx-ui-loader';
import { UserModule } from './pages/users/users.module';
import { CitiesModule } from './pages/users/governments/cities/cities.module';
import { AgenciesModule } from './pages/users/agencies/agencies.module';
import { DropifyModule } from './core/cutomModules/dropify/dropify.module';
import { DriverLicenseTypesModule } from './pages/users/driver-license-types/driver-license-types.module';
import { DriversModule } from './pages/users/drivers/drivers.module';
import { VehicleModelsModule } from './pages/users/vehicle-models/vehicle-models.module';
import { TripsModule } from './pages/users/reports/trips/trips.module';
import { MyAccountModule } from './pages/schoolz/my-account/my-account.module';
import { PassengerModule } from './pages/users/reports/passenger/passenger.module';

import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { MessagingService } from './service/messaging.service';
import { environment } from '../environments/environment';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { PackagesComponent } from './saas/packages/packages.component';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#007dbe',
  // bgsOpacity: 0.5,
  // bgsPosition: POSITION.bottomCenter,
  // bgsSize: 60,
  // bgsType: SPINNER.rectangleBounce,
  fgsColor: '#007dbe',
  // fgsPosition: POSITION.centerCenter,
  // fgsSize: 60,
  // fgsType: SPINNER.chasingDots,
  // logoUrl: 'assets/angular.png',
  pbColor: '#007dbe',
  // pbDirection: PB_DIRECTION.leftToRight,
  // pbThickness: 5,
  // text: 'Welcome to ngx-ui-loader',
  // textColor: '#FFFFFF',
  // textPosition: POSITION.centerCenter
};
@NgModule({
  declarations: [
    AppComponent,
    PackagesComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutsModule,
    AppRoutingModule,
    MatSnackBarModule,
    NgxUiLoaderRouterModule, // import this module for showing loader automatically when navigating between app routes
    NgxUiLoaderHttpModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    SimpleNotificationsModule.forRoot(),
    UserModule,
    CitiesModule,
    AgenciesModule,
    DropifyModule,
    DriverLicenseTypesModule,
    DriversModule,
    VehicleModelsModule,
    TripsModule,
    MyAccountModule,
    PassengerModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // provider used to create fake backend
    FakeBackendProvider,
    MessagingService,
    AsyncPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
