import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MainService } from 'src/app/core/services/main.service';
import { formObject } from 'src/app/pages/helper/formObject';
import { ActivatedRoute, Router } from '@angular/router';

import { ServiceService } from '../service.service';

@Component({
  selector: 'app-create-update',
  templateUrl: './create-update.component.html',
  styleUrls: ['./create-update.component.scss']
})
export class CreateUpdateComponent implements OnInit {

  objectForm = new formObject;
  id = null;
  object = null;
  parent_id = null;
  support_year_from_list = [];
  constructor(
    private router: Router,
    private mainService: MainService,
    public formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private service: ServiceService,

  ) {
    for (let index = new Date().getFullYear(); index > 1990; index--) {
      this.support_year_from_list.push(index);
    }
    this.activatedRoute.params.subscribe(params => {
      this.id = params.id;
      this.parent_id = params.parent_id;
      this.setBreadCrumbItems();
      if (this.id)
        this.getObject(this.id);
    });
  }

  setBreadCrumbItems() {
    if (this.parent_id) {
      this.mainService.changeBreadCrumbItems([
        { label: 'Vehicle Models', path: '/dashboard/users/vehicle_models', active: false },
        { label: 'Vehicle Models ' + this.parent_id + ' SUb', path: '/dashboard/users/vehicle_models/' + this.parent_id + '/sub', active: false },
        { label: (this.id ? "Edit" : "Create"), path: "", active: true },
      ]);
      this.mainService.setTitle('Vehicle Models ' + this.parent_id + ' SUb ' + (this.id ? "Edit" : "Create"));
    }
    else {
      this.mainService.changeBreadCrumbItems([
        { label: 'Vehicle Models', path: '/dashboard/users/vehicle_models', active: false },
        { label: (this.id ? "Edit" : "Create"), path: "", active: true },
      ]);
      this.mainService.setTitle("Vehicle Models " + (this.id ? "Edit" : "Create"));
    }
  }

  getObject(id) {
    this.service.getObject(id).subscribe((data: any) => {
      if (!data.object) {
        this.mainService.openRoute(this.router.url.split("/")[1] + '/create');
        return;
      }
      this.object = data.object;
      this.mainService.languages.forEach(language => {
        this.objectForm.controller['name_' + language.id].setValue(this.object.translations[language.id] ? this.object.translations[language.id].name : "");
      });
      this.objectForm.controller.active.setValue(this.object.active);
      if (this.object.support_year_from)
        this.objectForm.controller.support_year_from.setValue(this.object.support_year_from);
    });
  }
  ngOnInit() {

    var validationObject = {
      // name: ['', [Validators.required]],
      active: [1],
    };
    if (this.parent_id) {
      validationObject['parent_id'] = [this.parent_id, [Validators.required]];
      validationObject['support_year_from'] = [null, [Validators.required]];
    }
    this.mainService.languages.forEach(element => {
      validationObject['name_' + element.id] = ['', [Validators.required]];
    });
    this.objectForm.formGroup = this.formBuilder.group(validationObject);

    this.objectForm.submitAction = function (parent) {
      console.log(this.formGroup.value);
      this.submit = true;
      this.loader = true;
      if (!this.formGroup.valid) {
        this.loader = false;
        return;
      }
      parent.service.createUpdate(this.formGroup.value, parent.id).subscribe((data: any) => {
        this.loader = false;
        this.submit = false;
        if (data.status == 1) {
          parent.mainService.show_notifications("Successfully " + (parent.id ? 'Edit' : 'Create'));
          if (!parent.id) {
            if (parent.parent_id) {
              parent.mainService.openRoute(parent.router.url.split("/")[1] + "/" + parent.router.url.split("/")[2] + '/' + parent.parent_id + '/sub/edit/' + data.id);
            }
            else {
              parent.mainService.openRoute(parent.router.url.split("/")[1] + "/" + parent.router.url.split("/")[2] + "/" + parent.router.url.split("/")[3] + '/edit/' + data.id);
            }
          }
        }
        else {
          parent.mainService.showFormErrors(data.errors);
        }
      });
    };
  }

}
