import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/core/services/main.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(
    private http: HttpClient,
    private mainService: MainService,
  ) { }

  public createUpdate(object, id = "") {
    let url = "user/vehicle_models/create_update";
    if (id)
      url += '/' + id;
    console.log(id);
    return this.http.post(this.mainService.apiUrl + url, object);
  }


  public getObject(id) {
    let url = "user/vehicle_models/get/" + id;
    return this.http.get(this.mainService.apiUrl + url);
  }

  public getAll(object, id = "") {
    let url = "";
    if (id)
      url = "user/vehicle_models/sub/" + id + "?page=" + object.page;
    else
      url = "user/vehicle_models?page=" + object.page;

    return this.http.get(this.mainService.apiUrl + url);
  }

  public deleteObject(id) {
    let url = "user/vehicle_models/delete/" + id;
    return this.http.get(this.mainService.apiUrl + url);
  }


}
