import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/core/services/main.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(
    private http: HttpClient,
    private mainService: MainService,
  ) { }
  public getTicketsCount() {
    let url = "user/ticket_complaints/count/unread";
    return this.http.get(this.mainService.apiUrl + url);
  }
}
